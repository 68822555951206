import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import { mediaServerImage } from "@inferno/renderer-shared-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { imageURI } from "../../lib/catalog";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { FeedDisplayHints } from "../../services/Sites.utils";

const ContentTile = loadable(() => import("../../components/tiles/ContentTile.component"));

export function getCardImage(lead: Webapi.LeadsCardFragment, hostname: string) {
  if (lead && lead.use_catalog_image && lead.catalog && lead.catalog.id && lead.catalog.kind) {
    return imageURI(hostname, lead.catalog.kind, lead.catalog.id);
  } else if (lead?.img_meta && !lead.use_catalog_image) {
    return mediaServerImage(hostname, lead.img_meta.bucket || "", lead.img_meta.base_id || "");
  }
  return undefined;
}

interface LeadFeedItemProps {
  lead: Webapi.LeadsCardFragment;
  index: number;
  displayHints: FeedDisplayHints;
  store?: Store;
}

const getLayout = (style: string) => {
  if (style === "horizontal") {
    return "thumb-left";
  } else if (style === "square") {
    return "square";
  } else {
    return "thumb-top";
  }
};

export const LeadFeedItem = inject("store")(
  observer(({ lead, index, displayHints, store }: LeadFeedItemProps) => {
    if (!store) {
      return null;
    }
    const { site, env } = store;
    const { t } = useTranslation();
    const { style } = displayHints;

    const hostname = env.IMAGE_HOST;
    const url = lead.link && lead.link.urls && lead.link.urls.web ? lead.link.urls.web : undefined;
    const target = lead.link ? lead.link.target : "";
    const imgUri = getCardImage(lead, hostname);
    const defaultImageSize = displayHints.isPodcast ? "330,330" : "330,186";
    const defaultImage =
      (site.sections.design?.station_logo
        ? formatImage(site.sections.design?.station_logo.id, env.IMAGE_HOST)
        : env.DEFAULT_IMAGE) +
      `?ops=gravity("center"),contain(${defaultImageSize}),new(),flood(%22%23ffffffff%22),swap(),boxmerge("over", "left: 20px, right: 20px"),quality(65)`;
    const alt = displayHints.isPodcast ? t("podcast_alt", { title: lead.title }) : undefined;
    return (
      <ContentTile
        title={lead.title}
        target={target || undefined}
        url={url}
        slug={`lead_${index}`}
        action="item_selected"
        thumb={imgUri || defaultImage}
        layout={getLayout(style)}
        alt={alt}
        className="feed-leads-item"
      />
    );
  }),
);

export default LeadFeedItem;
