import type { AppEnvironment } from "@inferno/renderer-shared-core";
import { mediaServerCatalogImage, mediaServerPlaylistImage } from "@inferno/renderer-shared-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { ImageTileProps } from "../ui/ImageTile.component";

export enum MusicPageType {
  RECENT = "recentlyplayed",
  TOP = "topsongs",
}

export interface Playlist {
  id: string;
  userId: string;
  name: string;
  description: string;
  author: string;
  urls: {
    web: string;
    image: string;
    play: string;
    goto: string;
  };
  reportingKey: string;
}

export const convertPodcast = (env: AppEnvironment, podcast: Webapi.PodcastFragment): ImageTileProps => ({
  title: podcast.title,
  url: `${env.NATIONAL_SITE_URL}/podcast/${podcast.slug}-${podcast.id}/`,
  imageUrl: mediaServerCatalogImage(env.IMAGE_HOST, "podcast", podcast.id, 224, 224),
});

export const convertPlaylist = (env: AppEnvironment, playlist: Playlist): ImageTileProps => ({
  title: playlist.name,
  url: playlist.urls.web,
  author: playlist.author,
  imageUrl: mediaServerPlaylistImage(env.IMAGE_HOST, playlist.userId, playlist.id, 224, 224),
});

export type CatalogKind = "album" | "artist" | "track" | "station" | "podcast" | "curated";

export function isCatalogKind(s: string): s is CatalogKind {
  // Including talk for compatibility.
  return ["album", "artist", "track", "station", "podcast", "curated", "talkShow"].includes(s);
}

export function imageURI(IMAGE_URI_BASE: string, kind: string, id: string | number) {
  if (kind === "station") {
    return `https://${IMAGE_URI_BASE}/v3/catalog/live/${id}`;
  }
  if (kind === "album") {
    return `https://${IMAGE_URI_BASE}/v3/catalog/album/${id}`;
  }
  if (kind === "talkShow" || kind === "podcast") {
    return `https://${IMAGE_URI_BASE}/v3/catalog/show/${id}`;
  }
  if (kind === "curated") {
    const parts = (id as string).split("::");
    return `https://${IMAGE_URI_BASE}/v3/user/${parts[0]}/collection/${parts[1]}`;
  }
  if (isCatalogKind(kind)) {
    return `https://${IMAGE_URI_BASE}/v3/catalog/${kind}/${id}`;
  }
  return undefined;
}
